<template>
    <div class="loader-line">
        <div class="bar"></div>
    </div>
</template>

<script>
export default {
    name: 'LoaderLine'
}
</script>

<style scoped>
@keyframes loader-animation {
    0% {
        left: -100%;
    }
    49% {
        left: 100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: -100%;
    }
}
.loader-line {
    position: relative;
    height: 8px;
    width: 100%;
}
.loader-line .bar {
    width: 100%;
    position: absolute;
    height: 8px;
    background-color: #6c757d;
    animation-name: loader-animation;
    animation-duration: 2.4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
</style>