<template>
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-10" >
                    <h3 class="font-weight-normal mt-0">
                        <small class="mr-2" style="display:inline-block">{{ format_date(item.created_at) }}</small>

                        <span class="mr-2">#{{ item.name }}</span>

                        <button class="btn-xs btn btn-outline-secondary btn-rounded mr-1" @click="modal.openModal(item)"> edit </button> 
                    </h3>
                    <table class="table table-responsive-md table-sm table-borderless mt-2 mb-0" v-if="item.links.length">
                        <tbody name="slide-left" is="transition-group" mode="out-in">
                            <tr v-for="(link, i) in filteredLinks" :key="link.link.id">
                                <td width="50%">{{ link.link.name ? link.link.name : link.link.redirect_link }}</td>
                                <td width="40%">
                                    <span class="mr-2">{{ REDIRECT_DOMAIN }}/<b>{{ link.link.short_link }}</b></span>
                                    <button class="btn-xs btn btn-outline-secondary btn-rounded mr-1" @click="copyTextToClipboard(`${REDIRECT_DOMAIN}/${link.link.short_link}`)"> copy </button> 
                                </td>
                                <th width="10%">
                                    {{ formatAggregate(link.link, 'stats_byday', '0', 'users') }}
                                    <small>/ {{ formatAggregate(link.link, 'stats_byday', '0', 'hits') }}</small>
                                </th>
                            </tr>
                            <tr v-if="hide_links" key="hide_button">
                                <td colspan="3" class="text-center"> 
                                    <a class="cursor-pointer" v-if="links_hidden" @click="toggleHideLinks()"> <i class="mdi mdi-arrow-down"></i> open {{ item.links.length - 1 }} more</a> 
                                    <a class="cursor-pointer" v-else @click="toggleHideLinks()"> <i class="mdi mdi-arrow-up"></i> hide</a> 
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="col-2">
                    <h1 class="text-right">
                        {{ formatAggregate(item, 'stats_byday', '0', 'users') }}
                        <small>/ {{ formatAggregate(item, 'stats_byday', '0', 'hits') }}</small>
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from '@/plugins/day'
import { copyTextToClipboard, getNested } from '@/plugins/utils'

export default {
    name: 'TagCard',
    props: ['item', 'modal'],
    data: () => {
        return {
            REDIRECT_DOMAIN: process.env.VUE_APP_REDIRECT_URL,
            links_hidden: true
        }
    },
    computed: {
        hide_links() {
            return this.item.links.length > 2
        },
        filteredLinks() {
            return  this.orderedLinks.filter((link, i) => {
                return !this.links_hidden || this.item.links.length <= 2 || i == 0
            })
        },
        orderedLinks() {
            let data = this.item.links || []
            let path = 'link.stats_byday.0.users'.split('.')
            
            data.sort((a, b) => {
                let a_value = getNested(a, ...path) || null
                let b_value = getNested(b, ...path) || null

                if(b_value == a_value) return 0
                return b_value > a_value ? 1 : -1
            })

            return data
        }
    },
    methods: {
        toggleHideLinks() {
            this.links_hidden = !this.links_hidden
        },
        format_date(date) {
			return dayjs(date).format('DD.MM HH:mm');
        },
        formatAggregate(item, ...path) {
            return getNested(item, ...path) || 0
        },
        copyTextToClipboard
    }
}
</script>

<style>

</style>