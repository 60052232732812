<template>
	<div>
        <new-tag @created="newItemCreated()"/>

        <div class="row mt-2">
            <div class="col-lg-6">
                <div class="input-group">
                    <input class="form-control" type="text" v-model="form.search" placeholder="Search tags by a name">
                    <div class="input-group-append">
                        <button class="btn btn-outline-input" type="button" @click=" form.search = '' ">X</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="form-group">
                    <select class="form-control" v-model="form.orderBy">
                        <option value="stats_byday.0.hits">By hits</option>
                        <option value="stats_byday.0.users">By users</option>
                        <option value="name">By name</option>
                        <option value="created_at">By date</option>
                        <option value="links.length">By links count</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <date-range-form :range="form.dates" @input="form.dates = $event" />
                </div>
            </div>
            <div class="col-lg-1">
                <div class="form-group text-right">
                    <button class="btn btn-outline-input btn-block" @click="data()" v-if="!loading"><i class="mdi mdi-reload"></i></button>
                    <button class="btn btn-outline-input btn-block" type="button" disabled v-else>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="sr-only"></span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loading">
            <transition-group  name="flip-list" tag="div" v-if="orderedData && orderedData.length">
                <tag-card v-for="(item, i) in orderedData" :key="item.id" :item="item" :modal="$refs.edit_tag"/>
            </transition-group>
            <div class="card border-secondary border mb-0" v-else>
                <div class="card-body">
                    <h5 class="card-title">No tags are added yet</h5>
                    <p class="card-text">Please type in a tag name above. A tag will group statistics from several links.</p>
                </div>
            </div>
        </div>
        <div style="overflow:hidden" v-else>
            <loader-line />
        </div>

        <edit-tag-modal ref="edit_tag" @update:form="editItem($event)" @remove:form="removeItem($event)"/>
	</div>
</template>

<script>
import DateRangeForm from '@/components/form/DateRange.vue'
import EditTagModal from '@/components/modal/EditTag.vue'
import NewTag from '@/components/tags/NewTagForm'
import TagCard from '@/components/tags/TagCard'
import LoaderLine from '@/components/util/LoaderLine'
import dayjs from '@/plugins/day'
import { getNested } from '@/plugins/utils'

import { getTagsWithStats } from '@/plugins/hasura/queries'

export default {
    name: 'Tags',
    data: () => {
        return {
            loading: false,
            items: [],
            REDIRECT_DOMAIN: process.env.VUE_APP_REDIRECT_URL,
            form: {
                search: '',
                dates: {
                    startDate: dayjs().startOf('day'),
                    endDate: dayjs().endOf('day')
                },
                orderBy: 'stats_byday.0.users'
            }
        }
    },
    computed: {
        filteredData() {
            if(!this.form.search) return this.items 

            let search = this.form.search.toLowerCase()
            return this.items.filter(el => {
                return  el.name && el.name.toLowerCase().indexOf(search) !== -1
            })
        },
        orderedData() {
            let data = this.filteredData || []
            let path = this.form.orderBy.split('.')

            data.sort((a, b) => {
                let a_value = getNested(a, ...path) || null
                let b_value = getNested(b, ...path) || null

                if(b_value == a_value) return 0
                return b_value > a_value ? 1 : -1
            })

            return data
        },
        campaign() {
            return this.$route.params.campaign
        }
    },
    async beforeMount() {
		await this.data()
    },
    methods: {
        async data() {
            this.loading = true

            var [err, response] = await this.$orm.queryRAW({
                query: getTagsWithStats,
                variables: {
                    startDate: this.form.dates.startDate,
                    endDate: this.form.dates.endDate,
                    campaign: this.campaign
                }
            })

            setTimeout(() => {
                this.loading = false
            }, 450)

            if(err) {
                console.error('/views/tags', 'methods.data', '$orm.queryAndSubscribe', err)
                return $.NotificationApp.send("Error", "Can't get tags", "top-right", "", "error")
            }
            
            console.debug('/views/tags', 'methods.data', '$orm.queryAndSubscribe', response.tags)
            this.items = response.tags
        },
        async editItem(data = {}) {
            let i = this.items.findIndex((el) => el.id == data.id)

            Object.keys(data).forEach((key) => {
                if(key == 'id') return;
                this.$set(this.items[i], key, data[key])
            })
        },
        removeItem(data = {}) {
            if(!data.id) return;
            let i = this.items.findIndex((el) => el.id == data.id)
            this.items.splice(i, 1);
        },
        async newItemCreated() {
            await this.data()
            this.form.orderBy = 'created_at'
        }
    },
    watch: {
        'form.dates'() {
            if(this.form.dates.endDate.toString() == this.form.dates.startDate.toString()) {
                this.form.dates.endDate = dayjs(this.form.dates.endDate).add(1, 'day')
            }
            this.data()
        },
        '$route.params.campaign'() {
            this.data()
        }
    },
    components: {
        NewTag,
        EditTagModal,
        DateRangeForm,
        TagCard,
        LoaderLine
    }
}
</script>

<style>
.reportrange-text {
    padding: 0.45rem 0.9rem !important;
    border: 1px solid #dee2e6 !important;
}
</style>